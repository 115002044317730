
/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
.back-to-top{
  position: fixed;
  top:90%;
  right: 5px;
  font-size: 45px;
  color: rgb(238, 146, 36);
  background-color: #eff3ef;
  border-radius: 100px;
  transition: .5s;
  cursor: pointer;
}
.back-to-top:focus{
  scroll-behavior: smooth;
}
/***********************************************************/

html {
    --scrollbarBG: rgb(30, 30, 31);
    --thumbBG: rgb(238, 146, 36);
}

body::-webkit-scrollbar {
    width: 10px;
}

body {
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

body::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
}

body::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border-radius: 25px;
    border: 0px solid var(--scrollbarBG);
}


/*************************************************************/
body {
  font-family: "Poppins", sans-serif;
  color: #444444;
}

a {
  text-decoration: none;
  color: #ffb03b;
}

a:hover {
  color: #ffc56e;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Satisfy", sans-serif;
}

/********************************************************************/

/*--------------------------------------------------------------
# NavBar
--------------------------------------------------------------*/
.head{
  margin-bottom: 70px;
  z-index: 999;
}
.navbar{
  background-color: rgba(0,0,0,0.8);
  height: 70px;
}
.navbar-dark .navbar-nav .nav-link{
  color: #e8ab30;
  margin-left: 20px;
  font-size: 28px;
  font-weight: 700;
  font-family: "Satisfy", sans-serif;
}

.navbar-toggler .navbar-toggler-icon{

  background-color: rgba(0, 0, 0, 0.9);
}
@media (max-width: 992px) {
  .navbar-dark .navbar-collapse{
    background-color: #100f0f;
  }
}


/*--------------------------------------------------------------
# Book a table button Menu
--------------------------------------------------------------*/
.Shopping-cart-icon {
  background: #ffb03b;
  color: #fff;
  border-radius: 50px;
  margin: 0 0 0 20px;
  padding: 10px 25px;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1px;
  transition: 0.3s;
  white-space: nowrap;
}

.Shopping-cart-icon:hover {
  background: #ffa012;
  color: #fff;
}

@media (max-width: 992px) {
  .Shopping-cart-icon {
    margin: 0 15px 0 0;
    padding: 8px 20px;
    letter-spacing: 1px;
  }
  .form-control{
  min-width: 200px;
  }
}
.Registration-icon {
  color: #fff;
  border-radius: 50px;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1px;
  transition: 0.3s;
  white-space: nowrap;
}





/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation
*/


/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.hero{
  display: contents;
}
.hero h2 {
  color: #fff;
  margin-bottom: 30px;
  font-size: 48px;
  font-weight: 700;
}

.hero h2 span {
  color: #ffb03b;
}

.hero p {
  width: 80%;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  margin: 0 auto 30px auto;
  color: #fff;
}
.carousel_cap{
  background-color: rgba(0,0,0,0.1);
  border-radius: 25px;
  position: absolute;
  top:20px;
  color: #1ff180;
}
.carousel_cap .btn-menu{
  border:2px solid #1ff180;
  background-color: #100f0f;
  padding: 15px;
  border-radius: 35px;
  transition: 1ms;
  font-weight: bolder;
}
.carousel_cap .btn-menu:hover{
  background-color: #11bd60;
  color: #100f0f;
}
.specials{
  max-height: 200px;
  max-width: 200px;
  border-radius: 100%;
}
.carousel_cap .l{
  color: #1ff180;
  margin-bottom: 30px;
  font-size: 25px;
  font-weight: 700;
}
@media (max-width: 990px) {
  .carousel-content p  {
   display: none;
  }
  .carousel_cap{
   background-color: rgba(0,0,0,0.3);
  border-radius: 25px;
  color: #1ff180;
  }
  .carousel_cap .l{
    display: none;
  }
  .hero h2 {
  color: #fff;
  margin-bottom:2px;
  font-size: 18px;
  font-weight: 700;
}
  .carousel_cap .btn-menu{
  border:2px solid #1ff180;
  background-color: #100f0f;
  padding: 5px;
  border-radius: 35px;
  transition: 1ms;
  font-weight: bold;
}
  .carousel-control-next-icon,.carousel-control-prev-icon{
    display: none;
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
}

.section-bg {
  background-color: white;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  margin: 15px 0 0 0;
  font-size: 32px;
  font-weight: 700;
  color: #5f5950;
}

.section-title h2 span {
  color: #ffb03b;
}

.section-title p {
  margin: 15px auto 0 auto;
  font-weight: 300;
}

@media (min-width: 1024px) {
  .section-title p {
    width: 50%;
  }
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 20px 0;
  background-color: #f2f1ef;
  min-height: 40px;
  margin-top: 120px;
}

@media (max-width: 992px) {
  .breadcrumbs {
    margin-top: 70px;
  }
}

.breadcrumbs h2 {
  font-size: 24px;
  font-weight: 300;
  margin: 0;
}

@media (max-width: 992px) {
  .breadcrumbs h2 {
    margin: 0 0 10px 0;
  }
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
}

.breadcrumbs ol li + li {
  padding-left: 10px;
}

.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #6c757d;
  content: "/";
}

@media (max-width: 768px) {
  .breadcrumbs .d-flex {
    display: block !important;
  }
  .breadcrumbs ol {
    display: block;
  }
  .breadcrumbs ol li {
    display: inline-block;
  }
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about {
  background: #fffaf3;
}

.about .content {
  padding: 0 80px;
}

.about .content h3 {
  font-weight: 400;
  font-size: 34px;
  color: #5f5950;
}

.about .content h4 {
  font-size: 20px;
  font-weight: 700;
  margin-top: 5px;
}

.about .content p {
  font-size: 15px;
  color: #848484;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li + li {
  margin-top: 15px;
}

.about .content ul li {
  position: relative;
  padding-left: 26px;
}

.about .content ul i {
  font-size: 20px;
  color: #ffb03b;
  position: absolute;
  left: 0;
  top: 2px;
}

.about .content p:last-child {
  margin-bottom: 0;
}

.about .video-box {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 400px;
  position: relative;
}

.about .play-btn {
  width: 94px;
  height: 94px;
  background: radial-gradient(#ffb03b 50%, rgba(255, 176, 59, 0.4) 52%);
  border-radius: 50%;
  display: block;
  position: absolute;
  left: calc(50% - 47px);
  top: calc(50% - 47px);
  overflow: hidden;
}

.about .play-btn::after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 100;
  transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.about .play-btn::before {
  content: '';
  position: absolute;
  width: 120px;
  height: 120px;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation: pulsate-btn 2s;
  animation: pulsate-btn 2s;
  -webkit-animation-direction: forwards;
  animation-direction: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: steps;
  animation-timing-function: steps;
  opacity: 1;
  border-radius: 50%;
  border: 5px solid rgba(255, 176, 59, 0.7);
  top: -15%;
  left: -15%;
  background: rgba(198, 16, 0, 0);
}

.about .play-btn:hover::after {
  border-left: 15px solid #ffb03b;
  transform: scale(20);
}

.about .play-btn:hover::before {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border: none;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 200;
  -webkit-animation: none;
  animation: none;
  border-radius: 0;
}

@media (max-width: 1024px) {
  .about .content, .about .accordion-list {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 992px) {
  .about .content {
    padding-top: 30px;
  }
  .about .accordion-list {
    padding-bottom: 30px;
  }
}

@-webkit-keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }
  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

@keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }
  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

/*--------------------------------------------------------------
# Whu Us
--------------------------------------------------------------*/
.why-us .box {
  padding: 50px 30px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  transition: all ease-in-out 0.3s;
  height: 100%;
}

.why-us .box span {
  display: block;
  font-size: 28px;
  font-weight: 700;
  color: #ffcf88;
}

.why-us .box h4 {
  font-size: 24px;
  font-weight: 600;
  padding: 0;
  margin: 20px 0;
  color: #6c665c;
}

.why-us .box p {
  color: #aaaaaa;
  font-size: 15px;
  margin: 0;
  padding: 0;
}

.why-us .box:hover {
  background: #ffb03b;
  padding: 30px 30px 70px 30px;
  box-shadow: 10px 15px 30px rgba(0, 0, 0, 0.18);
}

.why-us .box:hover span, .why-us .box:hover h4, .why-us .box:hover p {
  color: #fff;
}

/*--------------------------------------------------------------
# Menu Section
--------------------------------------------------------------*/
.backgroundMenu{
  z-index: 1;
  position: fixed;
}
.menu{
  position: absolute;
  background-color: rgba(0,0,0,0.4);
  min-width: 100%;
  min-height: 800px;
  z-index: 5;
}
.menu #menu-flters {
  padding: 0;
  margin: 0 auto 0 auto;
  border-radius: 50px;

}
.menu .menuNav th  {

  display: inline-block;
  line-height: 1;
  transition: all ease-in-out 0.3s;
  border: 2px solid #ffb03b;
  border-radius: 50px;
  background-color: rgba(0,0,0,0.8);
  cursor: pointer;
}
.menu .menuNav th .v{
   font-size: 14px;
   font-weight: bolder;
  padding-right: 20px;
}
.menu .menuNav th:hover,.menu .menuNav th .v:hover {
  color: #131313;
  background: #ffb03b;

}

.menu .menuNav th:last-child {
  margin-right: 0;
}
.menu .menuData{
  background-color: rgba(0,0,0,.5);
}
.menu .menuData .name{
  color: #f19409;
  font-weight: bolder;
}
.menu .menuData .price{
  color: #f6f6f6;
}
.menu .menuData .select{

}
/*--------------------------------------------------------------
# Specials
--------------------------------------------------------------*/
#specials {
  overflow: hidden;
}

.specials .nav-tabs {
  border: 0;
}

.specials .nav-link {
  border: 0;
  padding: 12px 15px 12px 0;
  transition: 0.3s;
  color: #433f39;
  border-radius: 0;
  border-right: 2px solid #e8e7e4;
  font-weight: 600;
  font-size: 15px;
}

#specials .nav-link:hover {
  color: #ffb03b;
}

#specials .nav-link.active {
  color: #ffb03b;
  border-color: #ffb03b;
}

#specials .tab-pane.active {
  -webkit-animation: fadeIn 0.5s ease-out;
  animation: fadeIn 0.5s ease-out;
}

#specials .details h3 {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #433f39;
}

#specials .details p {
  color: #777777;
}

#specials .details p:last-child {
  margin-bottom: 0;
}

@media (max-width: 992px) {
  #specials .nav-link {
    border: 0;
    padding: 15px;
  }
  #specials .nav-link.active {
    color: #fff;
    background: #ffb03b;
  }
}





/*--------------------------------------------------------------
# Gallery
--------------------------------------------------------------*/
.gallery {
  padding-bottom: 0;
}

.gallery .gallery-item {
  overflow: hidden;
  border-right: 3px solid #fff;
  border-bottom: 3px solid #fff;
}

.gallery .gallery-item img {
  transition: all ease-in-out 0.4s;
}

.gallery .gallery-item:hover img {
  transform: scale(1.1);
}

/*--------------------------------------------------------------
# Chefs
--------------------------------------------------------------*/
.chefs {
  background: url("/src/img/chefs-bg.jpg") center center no-repeat;
  background-size: cover;
  padding: 60px 0;
  position: relative;
}

.chefs::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  z-index: 9;
}

.chefs .container {
  position: relative;
  z-index: 10;
}

.chefs .member {
  text-align: center;
  margin-bottom: 80px;
  position: relative;
}

.chefs .member .pic {
  overflow: hidden;
}

.chefs .member .member-info {
  position: absolute;
  bottom: -80px;
  left: 20px;
  right: 20px;
  background: #fff;
  padding: 20px 0;
  color: #433f39;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}

.chefs .member h4 {
  font-weight: 500;
  margin-bottom: 10px;
  font-size: 16px;
  color: #6c665c;
  position: relative;
  padding-bottom: 10px;
  font-family: "Poppins", sans-serif;
}

.chefs .member h4::after {
  content: '';
  position: absolute;
  display: block;
  width: 50px;
  height: 1px;
  background: #ffcf88;
  bottom: 0;
  left: calc(50% - 25px);
}

.chefs .member span {
  font-style: italic;
  display: block;
  font-size: 13px;
}

.chefs .member .social {
  margin-top: 15px;
}

.chefs .member .social a {
  transition: color 0.3s;
  color: #7a7368;
}

.chefs .member .social a:hover {
  color: #ffb03b;
}

.chefs .member .social i {
  font-size: 16px;
  margin: 0 2px;
}

@media (max-width: 992px) {
  .chefs .member {
    margin-bottom: 110px;
  }
}

@media (min-width: 1024px) {
  .chefs {
    background-attachment: fixed;
  }
}


/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info-wrap {
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  padding: 30px;
}

.contact .info {
  background: #fff;
}

.contact .info i {
  font-size: 20px;
  color: #ffb03b;
  float: left;
  width: 44px;
  height: 44px;
  background: #fff6e8;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}

.contact .info h4 {
  padding: 0 0 0 60px;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #433f39;
  font-family: "Poppins", sans-serif;
}

.contact .info p {
  padding: 0 0 0 60px;
  margin-bottom: 0;
  font-size: 14px;
  color: #7a7368;
}

.contact .info:hover i {
  background: #ffb03b;
  color: #fff;
}

.contact .php-email-form {
  width: 100%;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
  padding: 30px;
  background: #fff;
}

.contact .php-email-form .form-group {
  padding-bottom: 8px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input, .contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}

.contact .php-email-form input {
  height: 44px;
}

.contact .php-email-form textarea {
  padding: 10px 12px;
}

.contact .php-email-form button[type="submit"] {
  background: #ffb03b;
  border: 0;
  padding: 10px 24px;
  color: #fff;
  transition: 0.4s;
  border-radius: 50px;
}

.contact .php-email-form button[type="submit"]:hover {
  background: #ffa012;
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*-------------------------------------------------------------
# Shopping cart
/*---------------------------------------*/
.backgroundCart{
    z-index: 1;
  position: fixed;
}
.cart{
  position: absolute;
  background-color: rgba(0,0,0,0.4);
  min-width: 100%;
  min-height: 800px;
  z-index: 5;
}
.body-cart{
  background-color: rgba(0,0,0,0.7);
}
/*-------------------------------------------------------------/
# Admin
 */
.admin{
  position: absolute;
  background-color: rgba(0,0,0,0.8);
  min-width: 100%;
  min-height: 800px;
  z-index: 5;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  background: #35322d;
  color: #fff;
  font-size: 14px;
  text-align: center;
  padding: 30px 0;
}

#footer h3 {
  font-size: 36px;
  font-weight: 700;
  color: #ffb03b;
  position: relative;
  padding: 0;
  margin: 0 0 15px 0;
}

#footer p {
  font-size: 15;
  font-style: italic;
  padding: 0;
  margin: 0 0 40px 0;
}

#footer .social-links {
  margin: 0 0 40px 0;
}

#footer .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #46423b;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .social-links a:hover {
  background: #ffb03b;
}

#footer .copyright {
  margin: 0 0 5px 0;
}

#footer .credits {
  font-size: 13px;
}
